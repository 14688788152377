<template>
  <div class="scroll-box">
    <!-- 左边按钮 -->
    <div v-show="showLeftIcon" @click="handleLeftClick" class="scroll-icon left-icon">
      <i class="el-icon-arrow-left"></i>
    </div>
    <!-- 文本超出，左右按钮滚动 -->
    <div ref="swiperScroll" class="left-right-swiper-scroll-box">
      <!-- 中间滚动区域 -->
      <div ref="swiperScrollContent" class="swiper-scroll-content">
        <div
          :class="currentIndex === index ? 'li active' : 'li'"
          @click="tabClick(item, index)"
          v-for="(item, index) in tabs"
          :key="index"
        >
          <p class="main-txt" v-show="item.mainTitle">{{ item.mainTitle }}</p>
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
    <!-- 右边按钮 -->
    <div v-show="showRightIcon" @click="handleRightClick" class="scroll-icon right-icon">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'leftRightSwiperScroll',
  props: {
    swiperList: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tabs: {
      type: Array,
      default: [],
    },
    height: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showRightIcon: false, // 是否显示右箭头
      swiperScrollWidth: 0, // 盒子的宽度
      swiperScrollContentWidth: 0, // 内容的宽度
      maxClickNum: 0, // 最大点击次数
      lastLeft: 0, // 上次滑动距离
      clickNum: 0, // 点击次数
      childrenList: [], // 传进来的子元素
      currentIndex: 0,
    };
  },
  computed: {
    showLeftIcon() {
      return this.clickNum > 0;
    },
  },
  
  mounted() {
    this.getBoxWidth();
    window.onresize = ()=>{
      this.getBoxWidth();
    }
    
  },
  methods: {
    getBoxWidth(){
      // 获取 HTMLCollection 转为 数组
      this.childrenList = [...this.$refs.swiperScrollContent.children];
      console.log('childrenList--->', this.childrenList);
      // console.log('swiperScroll--->', this.$refs.swiperScroll.getBoundingClientRect());
      // console.log('swiperScrollContent--->', this.$refs.swiperScrollContent.getBoundingClientRect());
      // 盒子的宽度
      this.swiperScrollWidth = this.$refs.swiperScroll.getBoundingClientRect().width;
      // 内容的宽度
      this.swiperScrollContentWidth = this.$refs.swiperScrollContent.getBoundingClientRect().width;
      // 比较盒子的宽度,跟内容的宽度：判断是否需要展示右边的按钮
      if (this.swiperScrollWidth < this.swiperScrollContentWidth) {
        this.showRightIcon = true;
      }
    },
    tabClick(item, index) {
      if (item.path) {
        this.currentIndex = index;
        setTimeout(() => {
          this.$router.push(item.path);
        }, 500);
      } else {
        this.currentIndex = index;
        this.$emit('tabChange', item, index);
      }
    },
    // 点击右箭头（左侧滚动）
    handleRightClick() {
      // 如果点击次数小于数组长度-1时，执行左滑动效果。
      if (this.clickNum < this.childrenList.length - 1) {
        // 获取当前元素宽度
        let width = this.childrenList[this.clickNum].getBoundingClientRect().width;
        // console.log(this.childrenList[this.clickNum], this.childrenList[this.clickNum].getBoundingClientRect());
        // 获取最后一个元素距离左侧的距离
        let lastItemOffsetLeft = this.childrenList[this.childrenList.length - 1].offsetLeft;
        // 获取最后一个元素宽度
        let lastWidth = this.childrenList[this.childrenList.length - 1].getBoundingClientRect().width;
        // console.log(
        //   'lastItemOffsetLeft-->',
        //   lastItemOffsetLeft,
        //   this.childrenList[this.childrenList.length - 1].getBoundingClientRect(),
        // );
        // 获取可视区域宽度
        const lookWidth = this.$refs.swiperScroll.clientWidth;
        // console.log('获取可视区域宽度-->lookWidth', lookWidth);
        // 如果最后一个元素距离左侧的距离+自身的宽度大于可视距离，表示最后一个元素没有出现，执行滚动效果
        if (lastItemOffsetLeft + lastWidth > lookWidth) {
          // 滚动距离（元素的magin-left值） = 负的它自己的长度 + 上一次滑动的距离
          this.$refs.swiperScrollContent.style.marginLeft = `${-width + this.lastLeft}px`;
          this.lastLeft = -width + this.lastLeft;
          // 点击次数+1
          this.clickNum++;
          // 记录到最后一个元素出现在可视区域时，点击次数的最大值。用于后面点击左侧箭头时判断右侧箭头的显示
          this.maxClickNum = this.clickNum;
        }
        // 如果最后一个元素距离左侧的距离小于于可视距离，需要隐藏右侧箭头 
        let timer = setTimeout(() => {
          // 重新：获取最后一个元素距离左侧的距离
          let lastItemOffsetLeft2 = this.childrenList[this.childrenList.length - 1].offsetLeft;
          console.log('lastItemOffsetLeft2-新的->', lastItemOffsetLeft2);
          if (lastItemOffsetLeft2 + lastWidth <= lookWidth) {
            this.showRightIcon = false;
          }
          clearTimeout(timer);
        }, 200);
      }
    },
    // 点击左箭头（右侧滚动）
    handleLeftClick() {
      // 当点击次数大于0时才触发，这样当点击次数clickNum等于1的到时候，clickNum--等于0.根据计算属性的判断会隐藏掉左箭头
      if (this.clickNum > 0) {
        // 获取当前元素宽度
        let width = this.childrenList[this.clickNum - 1].getBoundingClientRect().width;
        // 公示：滚动距离（元素的magin-left值） = 它自己的长度 + 上一次滑动的距离
        this.$refs.swiperScrollContent.style.marginLeft = `${this.lastLeft + width}px`;
        this.lastLeft = width + this.lastLeft;
        // 点击次数-1
        this.clickNum--;
        // 如果点击次数小于最大点击次数，说明最后一个元素已经不在可是区域内了，显示右箭头
        if (this.clickNum < this.maxClickNum) {
          this.showRightIcon = true;
        }
      }
    },
  },
};
</script>

<style lang='less' scoped>
.scroll-box {
  position: relative;
  margin-bottom: 0.54rem;
  .scroll-icon {
    display: flex;
    position: absolute;
    top: 0;
    width: 0.72rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 9;
    cursor: pointer;
    background: #fff;
    text-align: center;
    i {
      position: absolute;
      color: #4fa1a4;
      font-size: 0.36rem;
    }
    &.left-icon {
      left: 0;
      box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.1);
    }
    &.right-icon {
      right: 0;
      box-shadow: -3px 0px 3px rgba(0, 0, 0, 0.1); /*阴影出现在元素的左侧*/
    }
  }
}
.left-right-swiper-scroll-box {
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  margin: 0 0.72rem;
  .swiper-scroll-content {
    display: inline-flex;
    white-space: nowrap;
    transition: all 0.3s;
    font-size: 0.28rem;
    color: #3d3d3d;
    line-height: 0.4rem;
    margin: 0 auto;
    border-bottom: 2px solid #e0e5ed;
  }
}
.li {
  min-width: 1.5rem;
  text-align: center;
  cursor: pointer;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: .25rem 0.2rem;
  box-sizing: border-box;
  p{
    font-weight: 400;
    line-height: 0.4rem;
    font-size: 0.28rem;
    transition: 0.4s;
    text-align: center;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2px;
    width: 0px;
    height: 2px;
    background: #4fa1a4;
    transition: 0.4s;
  }
  &.active {
    p {
      color: #4fa1a4;
    }

    &::before {
      width: 0.8rem;
    }
  }
}
.tab-container {
  margin-bottom: 0.54rem;
  > ul {
    display: inline-flex;
    margin: 0 auto;
    height: 0.9rem;
    border-bottom: 2px solid #e0e5ed;
  }
}
</style>
